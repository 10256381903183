import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";

import { Link } from "react-router-dom";
import { ModalBody, Modal, Badge } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
} from "../../../../components/Component";

import { Row } from "reactstrap";
import { projectData, monthList } from "../ProjectData";
import { setDeadlineDays, showFormatedDate } from "../../../../utils/Utils";
import { useForm } from "react-hook-form";

import { Steps, Step } from "react-step-builder";
import { useDispatch } from "react-redux";
import {
  fetchIndicatorsList,
  fetchProfileData,
  fetchReportSessions,
  saveReport,
  updateReportLocalList,
} from "../../../auth/store/auth.action";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIndicatorsList,
  selectLocalReportList,
  selectProfileData,
  selectReportSessionsList,
} from "../../../auth/store/auth.slice";
import { IndicatorForm } from "../IndicatorForm";
import { PropagateLoader } from "react-spinners";
import ReportForm from "../Form/ReportForm";

const Header = (props) => {
  const indicatorsList = useSelector(selectIndicatorsList);

  return (
    <div className="steps clearfix">
      <ul>
        {Array.from(Array(indicatorsList.length + 1).keys()).map((i) => (
          <li key={i} className={props.current >= i + 1 ? "first done" : "first"}>
            <a href={"#wizard-01-h-" + i + 1} onClick={(ev) => ev.preventDefault()}>
              <h5>Qn {i + 1}</h5>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

export const HFReports = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  const [data, setData] = useState(projectData);
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
    lead: "",
    tasks: 0,
    team: [],
    totalTask: 0,
    date: new Date(),
  });
  const indicatorsList = useSelector(selectIndicatorsList);
  const { report, isLoading, isError } = useSelector(selectReportSessionsList);
  const user = useSelector(selectCurrentUser);
  // const { profileData, isLoading: profileLoader } = useSelector(selectProfileData);

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      title: "",
      subtitle: "",
      description: "",
      lead: "",
      tasks: 0,
      totalTask: 0,
      team: [],
      date: new Date(),
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // let currentYear = new Date(Date.now()).getFullYear();

  useEffect(() => {
    dispatch(fetchIndicatorsList(4));
    // dispatch(fetchProfileData(currentYear));
  }, []);

  useEffect(() => {
    dispatch(fetchReportSessions({ id: 4, currentPage }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <Head title="Project List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Reports</BlockTitle>
              <BlockDes className="text-soft">You have total {report?.totalItems} Reports</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              {user?.level == 4 && (
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button>
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                        <Button color="primary">
                          <Icon name="plus"></Icon>
                          <span>Submit Report</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead className="nk-tb-item nk-tb-head">
                <DataTableRow className="nk-tb-col-check">
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Report from </span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Report Month</span>
                </DataTableRow>

                <DataTableRow size="lg">
                  <span className="sub-text">Reported by</span>
                </DataTableRow>

                <DataTableRow size="lg">
                  <span className="sub-text">Reported at</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Action</span>
                </DataTableRow>
              </DataTableHead>

              {/* rendering component */}
              {report?.reportSessions?.length > 0
                ? report?.reportSessions?.map((item, index) => {
                    let levelObj = item?.report_level;

                    if (item?.report_level && typeof item?.report_level == "string") {
                      let level = item?.report_level;
                      levelObj = JSON.parse(level);
                    }

                    return (
                      <DataTableItem key={item?.id}>
                        <DataTableRow className="nk-tb-col-check">
                          <span className="sub-text">{index + 1}</span>
                        </DataTableRow>

                        <DataTableRow>
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <div className="project-info">
                              <Link to={`${process.env.PUBLIC_URL}/report-details/${item?.id}`}>
                                <h6 className="title">{levelObj?.name}</h6>
                              </Link>
                            </div>
                          </a>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span className="project-title"> {item?.month}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>
                            {item?.user?.firstName} {item?.user?.lastName}
                          </span>
                        </DataTableRow>

                        <DataTableRow size="mb">
                          <Badge className="badge-dim">
                            <Icon name="clock"></Icon>
                            <span>{showFormatedDate(item?.reported_at)}</span>
                          </Badge>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <Link to={`${process.env.PUBLIC_URL}/report-details/${item?.id}`}>Show</Link>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {report?.reportSessions?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={report?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No Reports found, if something trouble contact system admin</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="xl">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Submit reports</h5>
              <div className="mt-4">
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <ReportForm
                    setModal={setModal}
                    indicatorsList={indicatorsList}
                    level={4}
                    url={"/hf-report"}
                    config={config}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default HFReports;
