import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";
import Homepage from "../pages/Dashboard/Homepage";
import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
import RegionReports from "../pages/pre-built/projects/Reports/RegionReport";
import UserListRegularPage from "../pages/pre-built/user-manage/Users/UserListRegular";
import UserContactCardPage from "../pages/pre-built/user-manage/UserContactCard";
import ReportDetails from "../pages/pre-built/projects/Reports/Details/ReportDetails";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import MyProfileDetails from "../pages/pre-built/settings/MyProfileDetails";
import ZoneList from "../pages/pre-built/settings/SettingLists/ZoneList";
import WoredaList from "../pages/pre-built/settings/SettingLists/WoredaList";
import ZoneReports from "../pages/pre-built/projects/Reports/ZoneReport";
import WoredaReports from "../pages/pre-built/projects/Reports/WoredaReport";
import HFReports from "../pages/pre-built/projects/Reports/HFReport";
import CommunityReports from "../pages/pre-built/projects/Reports/CommunityReport";
import HFList from "../pages/pre-built/settings/SettingLists/HFList";
import CommunityList from "../pages/pre-built/settings/SettingLists/CommunityList";
import UserDetailsPage from "../pages/pre-built/user-manage/Users/UserDetailsRegular";
import IndicatorList from "../pages/pre-built/settings/indicator/IndicatorList";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/crypto`} component={Crypto}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/analytics`} component={Analytics}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest`} component={Invest}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route> */}

        {/*Pre-built Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/project-card`} component={ProjectCardPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/region-report`} component={RegionReports}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/zone-report`} component={ZoneReports}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/woreda-report`} component={WoredaReports}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/hf-report`} component={HFReports}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/community-report`} component={CommunityReports}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/zone-list`} component={ZoneList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/woreda-list`} component={WoredaList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/hf-list`} component={HFList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/community-list`} component={CommunityList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-list-regular`} component={UserListRegularPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/zone-list`} component={ZoneList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/indicators`} component={IndicatorList}></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-regular`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/report-details/:id`}
          render={(props) => (
            <UserContextProvider>
              <ReportDetails {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/me`}
          render={(props) => (
            <UserContextProvider>
              <MyProfileDetails {...props} />
            </UserContextProvider>
          )}
        ></Route>

        <Route //Context api added
          exact
          path={`${process.env.PUBLIC_URL}/user-contact-card`}
          render={() => (
            <UserContextProvider>
              <UserContactCardPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
