import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  fetchUserList,
  saveUser,
  fetchZoneList,
  login,
  fetchCurrentUser,
  fetchIndicatorsList,
  fetchReport,
  fetchReportSessions,
  updateReportLocalList,
  updateReportSessionLocal,
  fetchWoredaList,
  fetchProfileData,
  fetchHFList,
  fetchCommunityList,
  fetchDashboard,
  fetchUserDetail,
  fetchAllIndicatorsList,
} from "./auth.action";

const initialState = {
  userList: {
    users: [],
    isLoading: false,
    isError: false,
  },
  zoneList: {
    zones: [],
    isLoading: false,
    isError: false,
  },
  woredaList: {
    woredas: [],
    isLoading: false,
    isError: false,
  },
  hFList: {
    healthFacilities: [],
    isLoading: false,
    isError: false,
  },
  communityList: {
    communities: [],
    isLoading: false,
    isError: false,
  },
  indicatorsList: [],
  indicators: {
    data: [],
    isLoading: false,
    isError: false,
  },
  reportsList: {
    reports: [],
    isLoading: false,
    isError: false,
  },
  reportsLocalList: [],
  reportSession: [],
  reportSessionsList: {
    report: [],
    isLoading: false,
    isError: false,
  },
  dashboardData: [],
  currentUser: null,
  profileData: {
    profileData: [],
    isLoading: false,
    isError: false,
  },
  userDetail: null,
};

export const authSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
      state.state = "inited";
      state.currentUser = payload || null;
    });
    // fetch profile data
    builder.addCase(fetchProfileData.pending, (state, { payload }) => {
      state.profileData.isError = false;
      state.profileData.isLoading = true;
    });
    builder.addCase(fetchProfileData.fulfilled, (state, { payload }) => {
      state.profileData.profileData = payload || null;
      state.profileData.isLoading = false;
      state.profileData.isError = false;
    });
    builder.addCase(fetchProfileData.rejected, (state, { payload }) => {
      state.profileData.isLoading = false;
      state.profileData.isError = true;
    });

    // fetch user lists
    builder.addCase(fetchUserList.pending, (state, { payload }) => {
      state.state = "pending";
      state.userList.isLoading = true;
      state.userList.isError = false;
    });
    builder.addCase(fetchUserList.fulfilled, (state, { payload }) => {
      state.state = "fulfilled";
      state.userList.users = payload || [];
      state.userList.isLoading = false;
      state.userList.isError = false;
    });
    builder.addCase(fetchUserList.rejected, (state, { payload }) => {
      state.state = "rejected";
      state.userList.isLoading = false;
      state.userList.isError = false;
    });

    builder.addCase(fetchUserDetail.fulfilled, (state, { payload }) => {
      state.state = "fulfilled";
      state.userDetail = payload || null;
    });

    // fetch zones list
    builder.addCase(fetchZoneList.pending, (state, { payload }) => {
      state.zoneList.isLoading = true;
      state.zoneList.isError = false;
    });
    builder.addCase(fetchZoneList.fulfilled, (state, { payload }) => {
      state.zoneList.zones = payload || [];
      state.zoneList.isLoading = false;
      state.zoneList.isError = false;
    });
    builder.addCase(fetchZoneList.rejected, (state, { payload }) => {
      state.zoneList.isLoading = false;
      state.zoneList.isError = true;
    });

    builder.addCase(fetchDashboard.fulfilled, (state, { payload }) => {
      state.state = "inited";
      state.dashboardData = payload || [];
    });
    // fetch single reorts with single sessions
    builder.addCase(fetchReport.pending, (state, { payload }) => {
      state.state = "inited";
      state.reportsList.isLoading = true;
      state.reportsList.isError = false;
    });
    builder.addCase(fetchReport.fulfilled, (state, { payload }) => {
      state.reportsList.reports = payload ? payload : [];
      state.reportsList.isLoading = false;
      state.reportsList.isError = false;
    });
    builder.addCase(fetchReport.rejected, (state, { payload }) => {
      state.reportsList.isLoading = false;
      state.reportsList.isError = true;
    });

    // fetch report sessions
    builder.addCase(fetchReportSessions.pending, (state, { payload }) => {
      state.reportSessionsList.isLoading = true;
      state.reportSessionsList.isError = false;
    });
    builder.addCase(fetchReportSessions.fulfilled, (state, { payload }) => {
      state.reportSessionsList.report = payload ? payload : [];
      state.reportSessionsList.isLoading = false;
      state.reportSessionsList.isError = false;
    });
    builder.addCase(fetchReportSessions.rejected, (state, { payload }) => {
      state.reportSessionsList.isLoading = false;
      state.reportSessionsList.isError = true;
    });

    // builder.addCase(fetchUserList.pending, (state) => {
    //   state.state = "inited";
    // });

    // woredas fetch
    builder.addCase(fetchWoredaList.pending, (state, { payload }) => {
      state.woredaList.isLoading = true;
      state.woredaList.isError = false;
    });
    builder.addCase(fetchWoredaList.fulfilled, (state, { payload }) => {
      state.woredaList.woredas = payload || [];
      state.woredaList.isLoading = false;
      state.woredaList.isError = false;
    });
    builder.addCase(fetchWoredaList.rejected, (state, { payload }) => {
      state.woredaList.isLoading = false;
      state.woredaList.isError = true;
    });

    // health Facility fetch
    builder.addCase(fetchHFList.pending, (state, { payload }) => {
      state.hFList.isLoading = true;
      state.hFList.isError = false;
    });
    builder.addCase(fetchHFList.fulfilled, (state, { payload }) => {
      state.hFList.healthFacilities = payload || [];
      state.hFList.isLoading = false;
      state.hFList.isError = false;
    });
    builder.addCase(fetchHFList.rejected, (state, { payload }) => {
      state.hFList.isLoading = false;
      state.hFList.isError = true;
    });

    // fetch Communities
    builder.addCase(fetchCommunityList.pending, (state, { payload }) => {
      state.communityList.isLoading = true;
      state.communityList.isError = false;
    });
    builder.addCase(fetchCommunityList.fulfilled, (state, { payload }) => {
      state.communityList.communities = payload || [];
      state.communityList.isLoading = false;
      state.communityList.isError = false;
    });
    builder.addCase(fetchCommunityList.rejected, (state, { payload }) => {
      state.communityList.isLoading = false;
      state.communityList.isError = true;
    });

    builder.addCase(saveUser.fulfilled, (state, { payload }) => {
      state.state = "fulfilled";
    });

    builder.addCase(updateReportLocalList, (state, { payload }) => {
      const testdata = [...state.reportsLocalList, { ...payload }];

      state.reportsLocalList = testdata;
    });
    builder.addCase(updateReportSessionLocal, (state, { payload }) => {
      state.reportSession = payload;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.state = "inited";
      state.currentUser = payload?.data || null;
    });

    // indicators

    builder.addCase(fetchIndicatorsList.fulfilled, (state, { payload }) => {
      state.indicatorsList = payload || [];
      state.isError = false;
      state.isLoading = false;
    });
    // all indicator
    builder.addCase(fetchAllIndicatorsList.pending, (state, { payload }) => {
      state.indicators.data = [];
      state.indicators.isError = false;
      state.indicators.isLoading = true;
    });

    builder.addCase(fetchAllIndicatorsList.fulfilled, (state, { payload }) => {
      state.indicators.data = payload || [];
      state.indicators.isError = false;
      state.indicators.isLoading = false;
    });
    builder.addCase(fetchAllIndicatorsList.rejected, (state, { payload }) => {
      state.indicators.data = [];
      state.indicators.isError = true;
      state.indicators.isLoading = false;
    });
  },
});

export const selectAuth = (state) => state.auth;
export const authReducer = authSlice.reducer;
// user list
export const selectUserList = createSelector(selectAuth, (state) => state.userList);
export const selectUserDetail = createSelector(selectAuth, (state) => state.userDetail);
export const selectZoneList = createSelector(selectAuth, (state) => state.zoneList);
export const selectWoredaList = createSelector(selectAuth, (state) => state.woredaList);
export const selectHFList = createSelector(selectAuth, (state) => state.hFList);
export const selectCommunityList = createSelector(selectAuth, (state) => state.communityList);
export const selectCurrentUser = createSelector(selectAuth, (state) => state.currentUser);
// profile data
export const selectProfileData = createSelector(selectAuth, (state) => state.profileData);
export const selectIndicatorsList = createSelector(selectAuth, (state) => state.indicatorsList);
export const selectAllIndicators = createSelector(selectAuth, (state) => state.indicators);
export const selectReportsList = createSelector(selectAuth, (state) => state.reportsList);
export const selectReportSessionsList = createSelector(selectAuth, (state) => state.reportSessionsList);
export const selectLocalReportList = createSelector(selectAuth, (state) => state.reportsLocalList);
export const selectLocalReportSession = createSelector(selectAuth, (state) => state.reportSession);
export const selectDashboardData = createSelector(selectAuth, (state) => state.dashboardData);
