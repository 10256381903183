import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Modal, ModalBody, Form } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../../../components/Component";
import { filterStatus, levelOptions } from "../UserData";
import { findUpper, showFormatedDate } from "../../../../utils/Utils";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUserList, selectWoredaList, selectZoneList, selectHFList } from "../../../auth/store/auth.slice";
import { useDispatch } from "react-redux";
import { exportUser, fetchUserList, fetchWoredaList, fetchZoneList, saveUser } from "../../../auth/store/auth.action";
import { Link } from "react-router-dom";
import { postData, updateData } from "../../../../utils/api";
import { PropagateLoader } from "react-spinners";
import { useFormik } from "formik";
import * as yup from "yup";

const UserListRegularPage = () => {
  const [data, setData] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const dispatch = useDispatch();
  // const userData = useSelector(selectUserList);
  const { users, isLoading, isError } = useSelector(selectUserList);
  const { woredas } = useSelector(selectWoredaList);
  // const woredaData = useSelector(selectWoredaList);
  const { healthFacilities } = useSelector(selectHFList);
  // const hfData = useSelector(selectHFList);
  const { zones } = useSelector(selectZoneList);
  // const zoneData = useSelector(selectZoneList);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zoneId: "",
    woredaId: "",
    healthFacilityId: "",
    status: 1,
    level: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const userSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    password: yup.string().required("Password is required"),
    level: yup.string().required("Level is required"),
    status: yup.number().required("Status is required"),
    roleId: yup.number().required("Role is required"),
    zoneId: yup.string(),
    woredaId: yup.string(),
    healthFacilityId: yup.string(),
    communityId: yup.string(),
  });

  // re-fetching
  const reloadData = () => {
    setTimeout(() => {
      dispatch(fetchUserList({ currentPage, q: onSearchText }));
    }, 0);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
    // setSelectedData({});
    setErrorArray([]);
  };

  const { handleSubmit, values, handleBlur, handleChange, handleReset, resetForm, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      level: "",
      status: 1,
      roleId: 2,
      zoneId: "",
      password: "",
      woredaId: "",
      healthFacilityId: "",
      communityId: "",
    },

    onSubmit: async (values) => {
      if (modal.add) {
        // values.passwordConfirm = values.password
        // setIsLoading(true);
        const response = await postData(`/admin/signup`, values);
        if (response?.status == 422) {
          setErrorArray(response?.data?.errors);
          return;
        }
        reloadData();
        resetForm();
        setModal({ edit: false }, { add: false });
      } else {
        // setLoading(true);
        // const result = await updateData(`/woreda/${values.id}`, values);
        // if (result?.data?.status == false) {
        //   setErrorArray(result?.data?.errors);
        //   // setLoading(false);
        //   return;
        // }
      }
      // setLoading(false);
      reloadData();
      onFormCancel();
    },

    validationSchema: userSchema,
  });

  // const handleExport = (user) => {
  //   dispatch(exportUser(user));
  // };

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // // submit function to update a new item
  // const onEditSubmit = (submitData) => {
  //   const { name, email, phone } = submitData;
  //   let submittedData;
  //   let newitems = data;
  //   newitems.forEach((item) => {
  //     if (item.id === editId) {
  //       submittedData = {
  //         id: item.id,
  //         avatarBg: item.avatarBg,
  //         name: name,
  //         image: item.image,
  //         role: item.role,
  //         email: email,
  //         balance: formData.balance,
  //         phone: "+" + phone,
  //         emailStatus: item.emailStatus,
  //         kycStatus: item.kycStatus,
  //         lastLogin: item.lastLogin,
  //         status: formData.status,
  //         country: item.country,
  //       };
  //     }
  //   });
  //   let index = newitems.findIndex((item) => item.id === editId);
  //   newitems[index] = submittedData;
  //   setModal({ edit: false });
  //   resetForm();
  // };

  // function to change to suspend property for an item
  const suspendUser = async (id, data) => {
    try {
      await updateData(`/admin/users/${id}`, data);
      setTimeout(() => {
        dispatch(fetchUserList({ currentPage, q: onSearchText }));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {};

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log(errors, "errors");
  useEffect(() => {
    dispatch(fetchUserList({ currentPage, q: onSearchText }));
    dispatch(fetchZoneList({ all: 1 }));
    dispatch(fetchWoredaList({ all: 1 }));
  }, [dispatch, onSearchText, currentPage]);

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {users?.totalItems} users.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon mx-2" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                        Add User &nbsp;&nbsp;&nbsp;
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    <div className="form-wrap"></div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block"></span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by first name"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                {/* <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div>
                </DataTableRow> */}
                <DataTableRow>
                  <span className="sub-text">User</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Role</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Registered at</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Actions</span>
                </DataTableRow>
                {/* <DataTableRow size="lg">
                  <span className="sub-text">Download</span>
                </DataTableRow> */}
              </DataTableHead>

              {/* rendering component */}
              {users?.totalItems > 0
                ? users?.users?.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        {/* <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              defaultChecked={item.checked}
                              id={item.id + "uid1"}
                              key={Math.random()}
                              onChange={(e) => console.log("fdf")}
                            />
                            <label className="custom-control-label" htmlFor={item.id + "uid1"}></label>
                          </div>
                        </DataTableRow> */}
                        <DataTableRow>
                          <div className="user-card">
                            <UserAvatar
                              theme={item.avatarBg}
                              text={findUpper(item.firstName)}
                              image={item.image}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="tb-lead">
                                <a
                                  href="#title"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                  className="project-title"
                                >
                                  <div className="project-info">
                                    <Link to={`${process.env.PUBLIC_URL}/user-details/${item.id}`}>
                                      <h6 className="title">
                                        {item.firstName} {item.lastName}
                                      </h6>
                                    </Link>
                                  </div>
                                </a>

                                <span
                                  className={`dot dot-${
                                    item.status === "Active"
                                      ? "success"
                                      : item.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                  } d-md-none ms-1`}
                                ></span>
                              </span>

                              <span>{item.email}</span>
                            </div>
                          </div>
                        </DataTableRow>

                        <DataTableRow size="md">
                          <span>{item.email}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.role?.name}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{showFormatedDate(item.createdAt)}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <div className="d-flex flex-direction-column gap-2 align-items-center justify-content-center ">
                            <span
                              className={`tb-status text-${
                                item.status?.name === "Active"
                                  ? "success"
                                  : item.status?.name === "Pending"
                                  ? "warning"
                                  : "danger"
                              }`}
                            >
                              {item.status?.name}
                            </span>
                            {item.status.id == 1 ? (
                              <React.Fragment>
                                <li
                                  className="nk-tb-action-hidden list-unstyled"
                                  onClick={() => suspendUser(item.id, { statusId: 2 })}
                                >
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"suspend" + item.id}
                                    icon="user-cross-fill"
                                    direction="top"
                                    text="De-Activate"
                                  />
                                </li>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <li
                                  className="nk-tb-action-hidden list-unstyled"
                                  onClick={() => suspendUser(item.id, { statusId: 1 })}
                                >
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"suspend" + item.id}
                                    icon="user-fill"
                                    direction="top"
                                    text="Activate"
                                  />
                                </li>
                              </React.Fragment>
                            )}
                          </div>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <Link to={`${process.env.PUBLIC_URL}/user-details/${item.id}`}>Show</Link>
                        </DataTableRow>
                        {/* <DataTableRow>
                          <Button className=" d-none d-sm-inline-flex" onClick={() => handleExport(item)}>
                            <Icon name="download"></Icon>
                            <span>Export</span>
                          </Button>
                        </DataTableRow> */}
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {users?.totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={users?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
                resetForm();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add User</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <form className="row gy-4" noValidate onSubmit={handleSubmit}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter First Name"
                      />
                      {touched.firstName && <span className="invalid">{errors.firstName}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Last Name"
                      />
                      {touched.lastName && <span className="invalid">{errors.lastName}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder="Enter email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.email && <span className="invalid">{errors.email}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Password </label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {touched.password && <span className="invalid">{errors.password}</span>}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.phoneNumber && <span className="invalid">{errors.phoneNumber}</span>}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                      <label className="form-label">Level</label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control"
                          name="level"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.level}
                          id="level"
                        >
                          {levelOptions?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                        {touched.level && <span className="invalid">{errors.level}</span>}
                      </div>
                    </div>
                  </Col>

                  {[2, 3, 4].includes(parseInt(values.level)) && (
                    <Col md="4">
                      <div className="form-group">
                        <label className="form-label">Zone/Town</label>
                        <div className="form-control-wrap">
                          <select
                            className="form-control"
                            name="zoneId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zoneId}
                            id="zoneId"
                          >
                            {zones?.zones?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {touched.zoneId && <span className="invalid">{errors.zoneId}</span>}
                        </div>
                      </div>
                    </Col>
                  )}

                  {[3, 4].includes(parseInt(values.level)) && (
                    <Col md="4">
                      <div className="form-group">
                        <label className="form-label">Woreda</label>
                        <div className="form-control-wrap">
                          <select
                            className="form-control"
                            name="woredaId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.woredaId}
                            id="woredaId"
                          >
                            {woredas?.woredaList
                              ?.filter((ww) => values.zoneId == "" || ww.zone?.id == values.zoneId)
                              ?.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          {touched.woredaId && <span className="invalid">{errors.woredaId}</span>}
                        </div>
                      </div>
                    </Col>
                  )}
                  {[4].includes(parseInt(values.level)) && (
                    <Col md="4">
                      <div className="form-group">
                        <label className="form-label">HF/Hospital</label>
                        <div className="form-control-wrap">
                          <select
                            className="form-control"
                            name="healthFacilityId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.woredaId}
                            id="healthFacilityId"
                          >
                            {healthFacilities?.healthFacilityList
                              ?.filter((ww) => values?.woredaId == "" || ww.woreda?.id == values?.woredaId)
                              .map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          {touched.healthFacilityId && <span className="invalid">{errors.healthFacilityId}</span>}
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md m-2" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                            resetForm();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        {...register("name", { required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={Number(formData.phone)}
                        {...register("phone", { required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{
                            value: formData.status,
                            label: formData.status,
                          }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
      </Content>
    </React.Fragment>
  );
};
export default UserListRegularPage;
