import * as yup from "yup";

export const indicatorSchema = yup.object().shape({
  name: yup.string().required("Indicator Name is required"),
  // type: yup.string(),
  // type_label: yup.string(),
  // total_label: yup.string(),
  has_session: yup.boolean().required("Indicator Session is required"),
  has_gender: yup.boolean().required("provide if Indicator has gender "),
  has_age_category: yup.boolean().required("Indicator Age Category is required"),
  has_number_of_distributed: yup.boolean().required("Indicator Number of Distributed is required"),
  has_reach: yup.boolean().required("Indicator Reach is required"),
  has_estimated_cost: yup.boolean().required("Indicator Estimated Cost is required"),
  has_negative_positive: yup.boolean().required("Indicator Negative Positive is required"),
  is_woreda_level: yup.boolean().required("Indicator Woreda Level is required"),
  is_zone_level: yup.boolean().required("Indicator Zone Level is required"),
  is_region_level: yup.boolean().required("Indicator Region Level is required"),
  is_hf_level: yup.boolean().required("Indicator HF Level is required"),
  is_community_level: yup.boolean().required("Indicator Community Level is required"),
});
