import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import _ from "lodash";
import { Modal, ModalBody, Form } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
} from "../../../../components/Component";
import { findUpper } from "../../../../utils/Utils";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { selectWoredaList, selectZoneList } from "../../../auth/store/auth.slice";

import { fetchWoredaList, fetchZoneList, saveWoreda } from "../../../auth/store/auth.action";
import { PropagateLoader } from "react-spinners";
import ActionOptions from "../ActionOptions";
import { postData, updateData } from "../../../../utils/api";
import { useFormik } from "formik";
import * as yup from "yup";

const WoredaList = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const { zones } = useSelector(selectZoneList);
  const { woredas, isLoading, isError } = useSelector(selectWoredaList);

  const [sm, updateSm] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  // re-fetching
  const reloadData = () => {
    setTimeout(() => {
      dispatch(fetchWoredaList({ currentPage, all: 0 }));
    }, 0);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
    setSelectedData({});
    setErrorArray([]);
  };
  const woredaSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    zoneId: yup.string().required("Zone Name is required"),
  });

  const { handleSubmit, values, handleBlur, handleChange, handleReset, resetForm, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedData?.name || "", // string
      zoneId: selectedData?.zoneId || "",
    },

    onSubmit: async (values) => {
      if (modal.add) {
        setLoading(true);
        const result = await postData(`/woreda`, values);
        if (result?.data?.status === false) {
          setErrorArray(result?.data?.errors);
          setLoading(false);
          return;
        }
      } else {
        setLoading(true);
        const result = await updateData(`/woreda/${selectedData.id}`, values);

        if (result?.data?.status == false) {
          setErrorArray(result?.data?.errors);
          setLoading(false);
          return;
        }
      }
      setLoading(false);
      reloadData();
      onFormCancel();
    },

    validationSchema: woredaSchema,
  });

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    dispatch(fetchZoneList({ all: 1 }));
    dispatch(fetchWoredaList({ currentPage, all: 0 }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Woreda Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {woredas?.totalItems} Woreda.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>

                <DataTableRow size="md">
                  <span className="sub-text">Zone</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"> Actions</span>
                </DataTableRow>
              </DataTableHead>

              {/*rendering component*/}
              {woredas?.woredaList?.length > 0
                ? woredas?.woredaList?.map((item, index) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-card">
                            <UserAvatar
                              theme={item.avatarBg}
                              text={findUpper(item.name)}
                              image={item.image}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}{" "}
                                <span
                                  className={`dot dot-${
                                    item.status === "Active"
                                      ? "success"
                                      : item.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                  } d-md-none ms-1`}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{item.zone?.name}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <ActionOptions
                            setModal={setModal}
                            url={"woreda"}
                            item={item}
                            reloadData={reloadData}
                            setSelectedData={setSelectedData}
                            setLoading={setLoading}
                          />
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {woredas?.totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={woredas?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
        {/* add woreda */}
        <Modal isOpen={modal.add} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add Woreda</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit}>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter name"
                      />
                      {touched.name && <span className="invalid">{errors.name}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Zone/Town</label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control"
                          name="zoneId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zoneId}
                          id="zoneId"
                        >
                          <option>Select Zone</option>
                          {zones?.zones?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.zoneId && <span className="invalid">{errors.zoneId}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Adding ..." : " Add Woreda"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* edit woreda */}
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit Woreda</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray?.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit}>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter name"
                      />
                      {touched.name && <span className="invalid">{errors.name}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Zone/Town</label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control"
                          name="zoneId"
                          // defaultValue={selectedData.zoneId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zoneId}
                          id="zoneId"
                        >
                          {zones?.zones?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.zoneId && <span className="invalid">{errors.zoneId}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Editing ..." : " Edit Woreda"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default WoredaList;
