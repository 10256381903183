import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { appController } from "../../api/app.controller";
import { BASE_ROUTE } from "../../../utils/api";

export const fetchCurrentUser = createAsyncThunk("users/getMe", async (id, { rejectWithValue }) => {
  try {
    return await appController.getMe();
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
// featching all users by admin
export const fetchUserList = createAsyncThunk("users/fetchUserList", async (obj, { rejectWithValue }) => {
  try {
    return (await appController.getUserList(obj)).data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchUserDetail = createAsyncThunk("users/fetchUserDetail", async (id, { rejectWithValue }) => {
  try {
    return (await appController.getUserDetail(id)).data?.user;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchIndicatorsList = createAsyncThunk("indicators/fetchList", async (level, { rejectWithValue }) => {
  try {
    return (await appController.getIndicatorsList(level)).data?.IndicatorList;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchAllIndicatorsList = createAsyncThunk(
  "indicators/fetchAllList",
  async (level, { rejectWithValue }) => {
    try {
      return (await appController.getAllIndicatorsList(level)).data?.IndicatorList;
    } catch (e) {
      return rejectWithValue(e.serialize());
    }
  }
);
// fetch single report
export const fetchReport = createAsyncThunk("report/fetchReport", async (id, { rejectWithValue }) => {
  try {
    return (await appController.fetchReport(id)).data?.report;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchDashboard = createAsyncThunk("report/fetchDashboard", async (id, { rejectWithValue }) => {
  try {
    return (await appController.fetchDashboard()).data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});

///////////////// fetch sessions //////////////////////
export const fetchReportSessions = createAsyncThunk(
  "report/fetchReportSessionList",
  async (data, { rejectWithValue }) => {
    try {
      return (await appController.fetchReportSessionList(data)).data;
    } catch (e) {
      return rejectWithValue(e.serialize());
    }
  }
);
// get profile data by year
export const fetchProfileData = createAsyncThunk("report/fetchProfileData", async (year, { rejectWithValue }) => {
  try {
    const data = (await appController.getProfileData(year)).data?.profileData;
    return data;
  } catch (e) {
    console.log(e);
    return rejectWithValue(e.serialize());
  }
});
export const fetchWoredaList = createAsyncThunk("report/fetchWoredaList", async (data, { rejectWithValue }) => {
  try {
    return (await appController.getWoredaList(data))?.data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchHFList = createAsyncThunk("report/fetchHFList", async (id, { rejectWithValue }) => {
  try {
    return (await appController.getHFList(id))?.data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchCommunityList = createAsyncThunk("report/fetchCommunityList", async (id, { rejectWithValue }) => {
  try {
    return (await appController.getCommunityList(id))?.data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const fetchZoneList = createAsyncThunk("report/fetchZoneList", async (id, { rejectWithValue }) => {
  try {
    return (await appController.getZoneList(id)).data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveUser = createAsyncThunk("users/saveUser", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await appController.saveUser(data)).data;
    await dispatch(fetchUserList());
    return response;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveProfileReport = createAsyncThunk("profileData/save", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await appController.saveProfileData(data)).data;

    return response;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});

// save report
export const saveReport = createAsyncThunk("report/save", async (data, { dispatch, rejectWithValue }) => {
  try {
    await appController.saveReports(data);
    // dispatch(fetchReportSessions({ id: 1, currentPage: 1 }));
    // dispatch(fetchReportSessions({ id: 2, currentPage: 1 }));
    // dispatch(fetchReportSessions({ id: 3, currentPage: 1 }));
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const resetPassword = createAsyncThunk("reset/password", async (data, { dispatch, rejectWithValue }) => {
  try {
    await appController.resetPassword(data);
    dispatch(alert("User Password Reset Successfully"));
    return;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveZone = createAsyncThunk("zone/save", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await appController.saveZone(data)).data;
    await dispatch(fetchZoneList());
    return response;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveWoreda = createAsyncThunk("woreda/save", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await appController.saveWoreda(data)).data;
    await dispatch(fetchWoredaList());
    return response;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveHF = createAsyncThunk("hf/save", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await appController.saveHF(data)).data;
    await dispatch(fetchHFList());
    return response;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveCommunity = createAsyncThunk("community/save", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await appController.saveCommunity(data)).data;
    await dispatch(fetchCommunityList());
    return response;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const saveReportSession = createAsyncThunk("report/savereportSession", async (data, { rejectWithValue }) => {
  try {
    return (await appController.saveReportSession(data)).data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});

export const exportReport = createAsyncThunk("report/exportReport", async (reportSession, { rejectWithValue }) => {
  // try {
  //   fetch(BASE_ROUTE + `/export/${reportSession.id}`)
  //     .then((res) => {
  //       return res.blob();
  //     })
  //     .then((blob) => {
  //       const href = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       const name = Math.round(Math.random() * 1000);
  //       a.download = `${reportSession.report_level?.name}-${reportSession.month}-${name}-Report.xlsx`;
  //       a.href = href;
  //       a.click();
  //       a.href = "";
  //       a.parentNode?.removeChild(a);
  //     })
  //     .catch((err) => console.error(err));
  // } catch (e) {
  //   console.log(e);
  //   return rejectWithValue(e.serialize());
  // }
});
export const exportUser = createAsyncThunk("report/exportUser", async (user, { rejectWithValue }) => {
  // try {
  //   fetch(BASE_ROUTE + `/auth/admin/export/${user.id}`)
  //     .then((res) => {
  //       return res.blob();
  //     })
  //     .then((blob) => {
  //       const href = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       const name = Math.round(Math.random() * 1000);
  //       a.download = `${user.firstName}-${user.lastName}-${name}-Credential.xlsx`;
  //       a.href = href;
  //       a.click();
  //       a.href = "";
  //       a.parentNode?.removeChild(a);
  //     })
  //     .catch((err) => console.error(err));
  // } catch (e) {
  //   console.log(e);
  //   return rejectWithValue(e.serialize());
  // }
});
export const login = createAsyncThunk("auth/Login", async (data, { rejectWithValue }) => {
  try {
    return (await (await appController.login(data)).data)?.data;
  } catch (e) {
    return rejectWithValue(e.serialize());
  }
});
export const updateReportLocalList = createAction("reports/updateLocal", (payload) => ({ payload }));
export const updateReportSessionLocal = createAction("reports/updateReportSessionLocal", (payload) => ({ payload }));
