import React, { useState } from "react";
import { Button, Col, Form, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { fetchAllIndicatorsList } from "../../../auth/store/auth.action";
import { postData, updateData } from "../../../../utils/api";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { indicatorSchema } from "./schema";

const IndicatorForm = ({ setSelectedData, open, setOpen, newly, selectedData, setNewly }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const dispatch = useDispatch();

  const { handleSubmit, values, handleBlur, handleChange, handleReset, resetForm, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedData?.name || "", // string
      // type: selectedData?.type || "", // string
      // type_label: selectedData?.type_label || "", // string
      // total_label: selectedData?.total_label || "", // string
      has_session: selectedData?.has_session || false,
      has_gender: selectedData?.has_gender || false,
      has_age_category: selectedData?.has_age_category || false,
      has_number_of_distributed: selectedData?.has_number_of_distributed || false,
      has_reach: selectedData?.has_reach || false,
      has_estimated_cost: selectedData?.has_estimated_cost || false,
      has_negative_positive: selectedData?.has_negative_positive || false,
      is_woreda_level: selectedData?.is_woreda_level || false,
      is_zone_level: selectedData?.is_zone_level || false,
      is_region_level: selectedData?.is_region_level || false,
      is_hf_level: selectedData?.is_hf_level || false,
      is_community_level: selectedData?.is_community_level || false,
    },

    onSubmit: async (values) => {
      // convert string to boolean values
      const convertedValues = convertStringToBoolean(values);

      if (newly) {
        const result = await postData(`/indicators`, convertedValues);
        if (result?.data?.status === false) {
          setErrorArray(result?.data?.errors);
          setIsLoading(false);
          return;
        }
        resetForm();
        setIsLoading(false);
        reloadData();
        onFormCancel();
      } else {
        setIsLoading(true);
        const result = await updateData(`/indicators/${selectedData.id}`, convertedValues);

        if (result?.data?.status === false) {
          setErrorArray(result?.data?.errors);
          setIsLoading(false);
          return;
        }
        resetForm();
        setIsLoading(false);
        reloadData();
        onFormCancel();
      }
    },
    validationSchema: indicatorSchema,
  });
  const onFormCancel = () => {
    setOpen(false);
    setNewly(true);
    // resetting the form
    resetForm();
    setSelectedData({});
  };
  const reloadData = () => {
    setTimeout(() => {
      //   dispatch(fetchAllIndicatorsList({ currentPage, all: false }));
      dispatch(fetchAllIndicatorsList());
    }, 0);
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title"> {newly ? "Add New Indicator" : "Edit Indicator"}</h5>
            <div className="d-flex flex-column justify-content-end align-items-end">
              {errorArray?.length > 0 && (
                <div className="alert alert-danger">
                  <ul>
                    {errorArray.map((error, index) => {
                      return <li key={index}>{error}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-4">
              <Form className="row gy-4" noValidate onSubmit={handleSubmit}>
                {/* name */}
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Indicator Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && <span className="invalid">{errors.name}</span>}
                  </div>
                </Col>
                {/* type */}
                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Indicator Type</label>
                    <input
                      className="form-control"
                      type="text"
                      name="type"
                      placeholder="Enter Indicator Type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.type && <span className="invalid">{errors.type}</span>}
                  </div>
                </Col> */}
                {/* type label */}
                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Indicator Type Label</label>
                    <input
                      className="form-control"
                      type="text"
                      name="type_label"
                      placeholder="Enter Type Label"
                      value={values.type_label}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.type_label && <span className="invalid">{errors.type_label}</span>}
                  </div>
                </Col> */}
                {/* total label */}
                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Indicator Total Label</label>
                    <input
                      className="form-control"
                      type="text"
                      name="total_label"
                      placeholder="Enter Total Label"
                      value={values.total_label}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.total_label && <span className="invalid">{errors.total_label}</span>}
                  </div>
                </Col> */}
                <div>Indicator Characteristics</div>
                {/* session */}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label"> Involves Sessions ?</label>
                    <select
                      className="form-control"
                      name="has_session"
                      defaultValue={values.has_session}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_session && <span className="invalid">{errors.has_session}</span>}
                  </div>
                </Col>
                {/* gender */}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Indicator Classified in Gender ?</label>
                    <select
                      className="form-control"
                      name="has_gender"
                      defaultValue={values.has_gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_gender && <span className="invalid">{errors.has_gender}</span>}
                  </div>
                </Col>
                {/* age */}
                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Indicator Classified in Age ?</label>
                    <select
                      className="form-control"
                      name="has_age_category"
                      defaultValue={values.has_age_category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_age_category && <span className="invalid">{errors.has_age_category}</span>}
                  </div>
                </Col> */}
                {/* distributed */}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Involves Distribution of Materials ?</label>
                    <select
                      className="form-control"
                      name="has_number_of_distributed"
                      defaultValue={values.has_number_of_distributed}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_number_of_distributed && (
                      <span className="invalid">{errors.has_number_of_distributed}</span>
                    )}
                  </div>
                </Col>
                {/* reach */}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Involves Outreach ?</label>
                    <select
                      className="form-control"
                      name="has_reach"
                      defaultValue={values.has_reach}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_reach && <span className="invalid">{errors.has_reach}</span>}
                  </div>
                </Col>
                {/* estimated cost */}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Involves Cost Estimations ?</label>
                    <select
                      className="form-control"
                      name="has_estimated_cost"
                      defaultValue={values.has_estimated_cost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_estimated_cost && <span className="invalid">{errors.has_estimated_cost}</span>}
                  </div>
                </Col>
                {/* negative positive */}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Involve Positive and Negative Feedback ?</label>
                    <select
                      className="form-control"
                      name="has_negative_positive"
                      defaultValue={values.has_negative_positive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.has_negative_positive && <span className="invalid">{errors.has_negative_positive}</span>}
                  </div>
                </Col>
                <div>Indicator Availabilities</div>
                {/* region */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Region ?</label>
                    <select
                      className="form-control"
                      name="is_region_level"
                      defaultValue={values.is_region_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_region_level && <span className="invalid">{errors.is_region_level}</span>}
                  </div>
                </Col>
                {/* zone */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Zone ?</label>
                    <select
                      className="form-control"
                      name="is_zone_level"
                      defaultValue={values.is_zone_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_zone_level && <span className="invalid">{errors.is_zone_level}</span>}
                  </div>
                </Col>
                {/* woreda */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Woreda ?</label>
                    <select
                      className="form-control"
                      name="is_woreda_level"
                      defaultValue={values.is_woreda_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_woreda_level && <span className="invalid">{errors.is_woreda_level}</span>}
                  </div>
                </Col>
                {/* hf */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in HF ?</label>
                    <select
                      className="form-control"
                      name="is_hf_level"
                      defaultValue={values.is_hf_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_hf_level && <span className="invalid">{errors.is_hf_level}</span>}
                  </div>
                </Col>
                {/* community */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Community ?</label>
                    <select
                      className="form-control"
                      name="is_community_level"
                      defaultValue={values.is_community_level}
                      value={values.is_community_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_community_level && <span className="invalid">{errors.is_community_level}</span>}
                  </div>
                </Col>
                {/* button */}
                <div className="d-flex justify-content-end ">
                  {/* <Col size="12"> */}
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-danger "
                      >
                        Cancel
                      </a>
                    </li>
                    {newly ? (
                      <li>
                        <Button disabled={isLoading} color="primary" size="md" type="submit">
                          {isLoading ? "Adding ..." : "Add Indicator"}
                        </Button>
                      </li>
                    ) : (
                      <li>
                        <Button disabled={isLoading} color="primary" size="md" type="submit">
                          {isLoading ? "Updating ..." : "Update Indicator"}
                        </Button>
                      </li>
                    )}
                  </ul>
                  {/* </Col> */}
                </div>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

function convertStringToBoolean(value) {
  if (typeof value.has_session === "string") {
    value.has_session = value.has_session === "true";
  }
  if (typeof value.has_gender === "string") {
    value.has_gender = value.has_gender === "true";
  }
  if (typeof value.has_age_category === "string") {
    value.has_age_category = value.has_age_category === "true";
  }
  if (typeof value.has_number_of_distributed === "string") {
    value.has_number_of_distributed = value.has_number_of_distributed === "true";
  }
  if (typeof value.has_reach === "string") {
    value.has_reach = value.has_reach === "true";
  }
  if (typeof value.has_estimated_cost === "string") {
    value.has_estimated_cost = value.has_estimated_cost === "true";
  }
  if (typeof value.has_negative_positive === "string") {
    value.has_negative_positive = value.has_negative_positive === "true";
  }
  if (typeof value.is_woreda_level === "string") {
    value.is_woreda_level = value.is_woreda_level === "true";
  }
  if (typeof value.is_zone_level === "string") {
    value.is_zone_level = value.is_zone_level === "true";
  }
  if (typeof value.is_region_level === "string") {
    value.is_region_level = value.is_region_level === "true";
  }
  if (typeof value.is_hf_level === "string") {
    value.is_hf_level = value.is_hf_level === "true";
  }
  if (typeof value.is_community_level === "string") {
    value.is_community_level = value.is_community_level === "true";
  }

  return value;
}

export default IndicatorForm;
