import React from "react";
import { Button, Col, RSelect } from "../../../../components/Component";
import { Input, Row } from "reactstrap";
import { monthList, ageCategory } from "../ProjectData";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, selectLocalReportList } from "../../../auth/store/auth.slice";
import { useForm } from "react-hook-form";
import { fetchReportSessions, saveReport } from "../../../auth/store/auth.action";

const ReportForm = ({ level, index, setModal, indicatorsList, config }) => {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    watch,
    register,
    reset,
  } = useForm();

  const getReportLevel = (level, user) => {
    if (level === 1) {
      return user?.region;
    } else if (level === 2) {
      return user?.zone;
    } else if (level === 3) {
      return user?.woreda;
    } else if (level === 4) {
      return user?.health_facility;
    } else {
      return user?.community;
    }
  };
  const submitForm = (data) => {
    console.log(data, "formData");

    const reportList = transformObjectToArray(data, indicatorsList?.length, user, level);
    console.log(reportList, "reportList");

    const reportSession = {
      level: level,
      month: data.month,
      report_level: getReportLevel(level, user),
      level_id: getReportLevel(level, user)?.id,
      userId: user?.id,
      reported_at: Date.now(),
    };

    console.log(reportSession, "reportSession");

    dispatch(saveReport({ session: reportSession, report: reportList }));

    setTimeout(() => {
      dispatch(fetchReportSessions({ id: level, currentPage: 1 }));
    }, 1000);

    setModal({ edit: false, add: false });
    reset();
  };

  const maleCount = (i) => watch(`male_count${i}`);
  const femaleCount = (i) => watch(`female_count${i}`);
  const total = (i) => watch(`total${i}`);

  // (Number(maleCount(i)) || Number( femaleCount(i))) ? Number(maleCount(i)) + Number(femaleCount(i)) : total(i)
  const getTotal = (i) => {
    if (maleCount(i) && femaleCount(i)) {
      return Number(maleCount(i)) + Number(femaleCount(i));
    } else if (maleCount(i)) {
      return Number(maleCount(i));
    } else if (femaleCount(i)) {
      return Number(femaleCount(i));
    } else {
      return 0;
    }
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      {indicatorsList?.length > 0 ? (
        <Row className="gy-4">
          <Col md="12">
            <div className="form-group">
              <label className="form-label" htmlFor="month">
                Month List
              </label>
              <div className="form-control-wrap">
                <select className="form-control" id="month" name="month" {...register("month", { required: true })}>
                  {monthList?.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                {errors.month && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <div>No Indicators Found</div>
      )}

      {indicatorsList?.map((indicator, i) => (
        <div key={indicator.id} className="content clearfix">
          <Row className="gy-1">
            <Col md="12" className="pt-4 fs-6">
              {indicator.name}
              <input
                type="number"
                id={`parentId${i}`}
                className="form-control"
                name={`parentId${i}`}
                min="0"
                {...register(`parentId${i}`, { required: true })}
                value={indicator.id}
                hidden
              />
              <input
                type="number"
                id={`indicatorId${i}`}
                className="form-control"
                name={`indicatorId${i}`}
                min="0"
                {...register(`indicatorId${i}`, { required: true })}
                value={indicator.id}
                hidden
              />
            </Col>

            {/* {indicator.type_label && (
              <Col md="9">
                <div className="form-group">
                  <label className="form-label" htmlFor="title">
                    {indicator.type_label}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="title"
                      className="form-control"
                      name="title"
                      min="0"
                      {...register("title", { required: true })}
                    />
                    {errors.title && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )} */}

            {indicator.has_gender && (
              <Col md="4">
                <div className="form-group">
                  <label className="form-label" htmlFor={`male_count${i}`}>
                    Male Participants
                  </label>
                  <div className={`form-control-wrap`}>
                    <input
                      type="number"
                      id={`male_count${i}`}
                      className="form-control"
                      name={`male_count${i}`}
                      min="0"
                      {...register(`male_count${i}`, { required: true })}
                    />
                    {errors[`male_count${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {indicator.has_gender && (
              <Col md="4">
                <div className="form-group">
                  <label className="form-label" htmlFor={`female_count${i}`}>
                    Female Participants
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`female_count${i}`}
                      className="form-control"
                      name={`female_count${i}`}
                      min="0"
                      {...register(`female_count${i}`, { required: true })}
                    />
                    {errors[`female_count${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {!(
              indicator.has_gender ||
              indicator.has_session ||
              indicator.has_number_of_distributed ||
              indicator.has_estimated_cost ||
              indicator.has_reach ||
              indicator.has_negative_positive
            ) && (
              <Col md="4">
                <div className="form-group">
                  <label className="form-label" htmlFor={`total${i}`}>
                    Number
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id="total"
                      className="form-control "
                      name={`total${i}`}
                      min="0"
                      // value={getTotal(i)}
                      {...register(`total${i}`, { required: true })}
                    />
                    {errors.total && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}
            {indicator.has_gender && (
              <Col md="4">
                <div className="form-group">
                  <label className="form-label" htmlFor={`total${i}`}>
                    Total
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id="total"
                      className="form-control bg-light"
                      name={`total${i}`}
                      readOnly={true}
                      min="0"
                      value={getTotal(i)}
                      {...register(`total${i}`, { required: true })}
                    />
                    {errors.total && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}
            {/* Age Category */}
            {/* 
            {indicator.has_age_category && (
              <Col md="4">
                <div className="form-group">
                  <label className="form-label" htmlFor={`age_category${i}`}>
                    Age Category
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id={`age_category${i}`}
                      className="form-control"
                      name={`age_category${i}`}
                      min="0"
                      {...register(`age_category${i}`, { required: true })}
                    />
                    {errors.age_category && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )} */}

            {indicator.has_session && (
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor={`session${i}`}>
                    Number of Sessions
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`session${i}`}
                      className="form-control"
                      name={`session${i}`}
                      min="0"
                      {...register(`session${i}`, { required: true })}
                    />
                    {errors[`session${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {indicator.has_number_of_distributed && (
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor={`number_of_distributed${i}`}>
                    Number of Distributed
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`number_of_distributed${i}`}
                      className="form-control"
                      name={`number_of_distributed${i}`}
                      min="0"
                      {...register(`number_of_distributed${i}`, { required: true })}
                    />
                    {errors[`number_of_distributed${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {indicator.has_estimated_cost && (
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor={`estimated_cost${i}`}>
                    Estimated Cost(ETB)
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`estimated_cost${i}`}
                      className="form-control"
                      name={`estimated_cost${i}`}
                      min="0"
                      {...register(`estimated_cost${i}`, { required: true })}
                    />
                    {errors[`estimated_cost${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {indicator.has_reach && (
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor={`number_of_reach${i}`}>
                    Number of Reach
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`number_of_reach${i}`}
                      className="form-control"
                      name={`number_of_reach${i}`}
                      min="0"
                      {...register(`number_of_reach${i}`, { required: true })}
                    />
                    {errors[`number_of_reach${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {/* negative positive */}
            {indicator.has_negative_positive && (
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor={`positive_comment_count${i}`}>
                    Number of Positive comment
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`positive_comment_count${i}`}
                      className="form-control"
                      name={`positive_comment_count${i}`}
                      min="0"
                      {...register(`positive_comment_count${i}`, { required: true })}
                    />
                    {errors[`positive_comment_count${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}

            {indicator.has_negative_positive && (
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor={`negative_comment_count${i}`}>
                    Number of Negative comment
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id={`negative_comment_count${i}`}
                      className="form-control"
                      name={`negative_comment_count${i}`}
                      min="0"
                      {...register(`negative_comment_count${i}`, { required: true })}
                    />
                    {errors[`negative_comment_count${i}`] && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      ))}

      {indicatorsList?.length > 0 && (
        <div className="actions clearfix">
          <ul className="d-flex justify-content-end">
            <li>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </li>
          </ul>
        </div>
      )}
    </form>
  );
};

function transformObjectToArray(obj, length, user, level) {
  const result = [];
  const keys = Object.keys(obj);

  const getLevelObject = (level, user) => {
    if (level === 1) {
      return { regionId: user?.region?.id };
    } else if (level === 2) {
      return { zoneId: user?.zone?.id };
    } else if (level === 3) {
      return { woredaId: user?.woreda?.id };
    } else if (level === 4) {
      return { healthFacilityId: user?.health_facility?.id };
    } else {
      return { communityId: user?.community?.id };
    }
  };

  for (let i = 0; i < length; i++) {
    // preparing the real key
    const femaleCountKey = `female_count${i}`;
    const maleCountKey = `male_count${i}`;
    const session = `session${i}`;
    const total = `total${i}`;
    const number_of_distributed = `number_of_distributed${i}`;
    const number_of_reach = `number_of_reach${i}`;
    const positive_comment_count = `positive_comment_count${i}`;
    const negative_comment_count = `negative_comment_count${i}`;
    const estimated_cost = `estimated_cost${i}`;
    const age_category = `age_category${i}`;
    const parentId = `parentId${i}`;
    const indicatorId = `indicatorId${i}`;

    // grabbing the values from the object
    const femaleCount = obj[femaleCountKey] || 0;
    const maleCount = obj[maleCountKey] || 0;
    const sessionCount = obj[session] || 0;
    const totalCount = obj[total] || 0;
    const number_of_distributedCount = obj[number_of_distributed] || 0;
    const number_of_reachCount = obj[number_of_reach] || 0;
    const positive_comment_countCount = obj[positive_comment_count] || 0;
    const negative_comment_countCount = obj[negative_comment_count] || 0;
    const estimated_costCount = obj[estimated_cost] || 0;
    const age_categoryCount = obj[age_category] || 0;
    const parentIdCount = obj[parentId] || 0;
    const indicatorIdCount = obj[indicatorId] || 0;

    result.push({
      female_count: femaleCount,
      male_count: maleCount,
      session: sessionCount,
      total: totalCount,
      number_of_distributed: number_of_distributedCount,
      number_of_reach: number_of_reachCount,
      positive_comment_count: positive_comment_countCount,
      negative_comment_count: negative_comment_countCount,
      estimated_cost: estimated_costCount,
      age_category: age_categoryCount,
      parentId: parentIdCount,
      indicatorId: indicatorIdCount,
      ...getLevelObject(level, user),
    });
  }

  return result;
}

export default ReportForm;
